.dropdown-outer {
  cursor: pointer;
  position: relative;
}
.dropdown-inner {
  margin-top: 1rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  padding: 2rem;
  margin-left: -2rem;
  border-end-end-radius: 1rem;
  border-bottom-left-radius: 1rem;
  backdrop-filter: blur(10px);
  background: white;
  z-index: 100;
  box-shadow: 0px 7px 6px -4px rgba(0, 0, 0, 0.2);
  position: absolute;
  /* right: -124px; */
}
.dropdown-inner-mobile {
  backdrop-filter: blur(0px) !important;
  background: #2a2a2a !important;
  border: none !important;
}
.dropdown-hover {
  position: absolute;
  background-color: rgba(255, 0, 0, 0.175);
  height: 12rem;
  width: 8rem;
}
.selected-lang-container {
  display: flex;
  gap: 1rem;
}

.lang-item {
  display: flex;
  filter: brightness(100%);
  transition: all 0.1s;
  color: #555;
  align-items: center;
  text-decoration: none;
}
.lang-item:hover {
  filter: brightness(150%);
  color: #666;
}
.lang-item__flag {
  --img-size: 2.35rem;
  width: var(--img-size);
  height: var(--img-size);
  border-radius: 50%;
  margin-right: 0.8rem;
  object-fit: cover;
}
.lang-item__code {
  text-transform: capitalize;
  align-items: center;
  flex-shrink: 0;
  font-size: 1.6rem;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  transition: all 0.3s;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #555;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0;
}

.pc-only {
  display: block;
}
.mobile-only {
  display: none;
}
@media (max-width: 768px) {
  .pc-only {
    display: none;
  }
  .mobile-only {
    display: block;
  }
  .dropdown-outer {
    position: static;
  }
  .dropdown-inner {
    position: absolute;
  }
}
